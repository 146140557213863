import styled from 'styled-components';

import { fonts } from '@/theme';

export const Container = styled.div`
  padding: 10px 20px;
  @media (max-width: 991px) {
    padding: 6px 10px;
  }
`;

export const Text = styled.p`
  ${fonts.text}
  min-height: 45px;
  @media (max-width: 991px) {
    ${fonts.smallText22}
    min-height: 22px;
  }
`;

export const Link = styled.a`
  ${fonts.text}
  @media (max-width: 991px) {
    ${fonts.smallText22}
  }
`;
