/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { useObserver } from '@/hooks';

import { Container, Text, Link } from './styles';

const Imprint = () => {
  const { contentJson } = useStaticQuery(
    graphql`
      query {
        contentJson(imprint: { id: { eq: "imprint" } }) {
          imprint {
            links {
              href
              name
            }
            texts
          }
        }
      }
    `
  );
  const { texts, links } = contentJson.imprint;

  const { setPosition } = useObserver();

  return (
    <Container>
      <div onTouchStart={setPosition} onMouseEnter={setPosition}>
        {texts.map((text, key) => (
          <Text key={key}>{text}</Text>
        ))}
      </div>
      <div onTouchStart={setPosition} onMouseEnter={setPosition}>
        {links.map(({ name, href }, key) => (
          <Fragment key={key}>
            <Link key={key} href={href} target="_blank" rel="noreferrer nofollow">
              {name}
            </Link>
            <br />
          </Fragment>
        ))}
      </div>
    </Container>
  );
};

export default Imprint;
